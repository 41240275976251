
<template>
    <MainHeader />
    <div class="flex flex-row justify-center mt-10">
        <div class="max-w-2xl flex flex-col items-center text-center px-3">
            <div class="justify-center w-24">
                <img src="../assets/svg/tick.svg">
            </div>
            <div class="font-bold text-3xl mt-10">Děkujeme. Platba proběhla v pořádku 👍🏻</div>
            <p class="mt-5 text-stone-600 ">Brzy se vám ozveme s více detaily na e-mail.</p>
            <div class="mt-8">
                <router-link to="/">
                    <BasicButton text="Zpět na hlavní stránku" />
                </router-link>
            </div>
        </div>
    </div>
    <teleport to="body">
        <PopupWindow v-if="error">
            <div class="block">
                <div class="mb-6 font-semibold">Omlouváme se, ale došlo někde k chybě. Abychom předešli možným problémům, kontaktujte nás prosím na +420 731 657 657.</div>
                <div class="flex">
                    <PetButton class="mr-3" text="Zavřít" @click="error = false" />
                    <router-link to="/">
                        <PetButton text="Vrátit se domů" />
                    </router-link>
                </div>
            </div>
        </PopupWindow>
    </teleport>
</template>


<script setup>
import { useSeoMeta } from '@vueuse/head'

useSeoMeta ({
	title: 'Platba proběhla v pořádku 👍🏻 | Najdi Mazlíčka',
    description: 'Brzy se vám ozveme s více detaily na e-mail.',
    ogDescription: 'Brzy se vám ozveme s více detaily na e-mail.',
    ogTitle: 'Platba proběhla v pořádku 👍🏻 | Najdi Mazlíčka',
    ogImage: 'https://najdimazlicka.cz/og.jpg',
    twitterCard: 'summary_large_image',
    robots: "noindex"
});

</script>

<script>
import BasicButton from '../components/BasicButton.vue'
import MainHeader from '../components/MainHeader.vue'
import PopupWindow from '../components/PopupWindow.vue'

const axios = require('axios')

const server_url = process.env.SERVER_URL
const url = `${server_url}/api`
const filter = "?filters[uid][$eq]="

export default {
    name: "FailureView", 
    components: {
        BasicButton,
        MainHeader,
        PopupWindow
    },
    data() {
        return {
            uid: "",
            petId: 0,
            error: false
        }
    },
    created() {
        this.uid = this.$cookies.get('mazel-pet-info').uid
    },
    async mounted() {
        await axios.get(url + '/pets' + filter + this.uid)
            .then(res => {
                this.petId = res.data.data[0].id
            })
            .catch(err => {
                this.error = true
                console.log(err)
            })

        await axios.put(url + "/pets/" + this.petId, {
            data: {
                paid: true
            }
        })

        const userEmail = this.$cookies.get('mazel-pet-info').userEmail
        const petName = this.$cookies.get('mazel-pet-info').petName
        const userPhone = this.$cookies.get('mazel-pet-info').userPhone
        const userName = this.$cookies.get('mazel-pet-info').userName
        


        const payload = {
            userEmail: userEmail,
            id: this.petId,
            petName: petName,
            userName: userName,
            userPhone: userPhone,
            uid: this.uid,
        }

        await axios.post(url + '/email-success', payload)
            .catch(err => {
                this.error = true
                console.log(err)
            })
    }
}
</script>