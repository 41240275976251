<template>
  <div class="isolate relative">
    <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-32">
      <div class="mx-auto max-w-4xl divide-y divide-stone-900/10">
        <h2 class="text-2xl sm:text-3xl font-bold leading-10 tracking-tight text-stone-900">Často kladené otázky</h2>
        <dl class="mt-10 space-y-6 divide-y divide-stone-900/10">
          <Disclosure as="div" v-for="faq in faqs" :key="faq.question" class="pt-6" v-slot="{ open }">
            <dt>
              <DisclosureButton class="flex w-full items-start justify-between text-left text-stone-900">
                <span class="text-base font-semibold leading-7">{{ faq.question }}</span>
                <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon v-if="!open" class="h-6 w-6" aria-hidden="true" />
                  <MinusSmallIcon v-else class="h-6 w-6" aria-hidden="true" />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel as="dd" class="mt-2 pr-12">
              <p class="text-base leading-7 text-stone-600">{{ faq.answer }}</p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>


  <component :is="'script'" type="application/ld+json">
    {{ StructuredFaq(faqs) }}
  </component>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'

const faqs = [
  {
    question: "Jaký je rozdíl mezi klasickým příspěvkem a naší službou?",
    answer:
      "Používáme speciální marketingové nástroje určené pro firmy, které se většinou snaží prodat nějaký produkt. My však pomocí této strategie hledáme vaše ztracené mazlíčky. Za tento nástroj sice musíme zaplatit nemalé peníze, ale umožní nám potom naplánovat kampaň přesně podle našich představ, což s normálními příspěvky není možné. Jde například o oslovení lidí v určité lokalitě, kterou si nastavíme, mnohonásobně větší dosah, různé filtry a tak dále. Příspěvky v kampaních však vypadají úplně stejně, jak je znáte. Lidé je mohou likovat, sdílet mezi své známé nebo kometovat.",
  },
  {
    question: "Jak přesně kampaně probíhají?",
    answer:
      "Okamžitě po odeslání objednávky začínáme plánovat celou kampaň, vytvoříme potřebnou grafiku a nastavíme reklamy. Vše se poté odešle ještě na schválení do Facebooku, kde to většinou do hodiny celé spustí. V tu chvíli se přísvěvky z naší pátrací kampaně začnou zobrazovat lidem z místa ztráty na sítích Facebook a Instagram - klasicky na jejich zdi nebo ve formě stories. Lidé poté v průběhu následujících dní tyto příspěvky různě sdílí mezi své známé, likují, ale hlavně kometují různé stopy. Často se tak dozvíme i menší, ovšem velmi důležité detaily, které by vám lidé jen tak sami od sebe nezavolali. Kampaně následně přizpůsobujeme na základě těchto informací (například změna lokace, popis, atd.) a denně vás informujeme o jakémkoliv vývoji.",
  },
  {
    question: "Proč je tento způsob tak efektivní?",
    answer:
      "Při hledání je zdaleka nejdůležitější rychlost. S námi jste schopni do pár hodin o ztrátě informovat obrovské množství lidí přímo v lokalitě ztráty. To s různými letáky, SMS hledáním nebo Facebook skupinami jednoduše nejde. Druhá velká výhoda je ta, že lidé v komentářích budou často kometovat i menší, ale velmi důležité stopy, které by vám u klasikého hledání nikdo nezavolal. My tyto informace následně využíváme a za chodu upravujeme vaší kampaň (7 dní v týdnu). Díky tomu se nám také podaří 74% zvířat v pořádku vrátit domů.",
  },
  {
    question: "Jak dokážeme zacílit ty správné lidi?",
    answer:
      "Facebook a Instagram nám pomocí jejich marketingových nástrojů umožňuje zaměřit přesně ty lidi, kteří bydlí v určitém okruhu od ztráty vašeho mazlíčka. Tradičně oslovujeme všechny obyvatele v okruhu 10km (záleží však na terénu, velikosti okolních měst, atd.). Někdy je to tedy více, někdy méně.",
  },
  {
    question: "Jak bude vypadat konkrétní inzerát?",
    answer:
      "Inzeráty tvoříme ve formě příspěvků na Facebook, Instagram a Instagram příběhů (Stories). Na konkrétní ukázku se můžete podívat při registraci.",
  },
  {
    question: "Jaké lidi oslovujete? Kolik se osloví lidí? A za jak dlouho?",
    answer:
      "Jednoduše všechny potencionální zachránce z okolí ztráty vašeho mazlíčka. Mimo to se také snažíme cílovou skupinu filtrovat na základě různých ostatních atributů hlavně proto, aby hledání bylo co nejefektivnější, obzvláště ve větších městech, kde se pohybuje opravdu hodně lidí. V takových případech dokážeme oslovit i 40 tisíc lidí - pokud je třeba. Počet oslovených ale obecně velmi závisí na místě ztráty. Naše klasické kampaně trvají standardně 7 dní, přičemž první 3 dny jsou nejvíce intenzivní. Nabízíme však i levnější balíček který trvá jen 2 dny.",
  },
  {
    question: "Za jak dlouho od odeslání registrace kampaň spustíte?",
    answer:
      "Ihned po přijetí registrace vaše informace ještě jednou zkontrolujeme, zda-li je vše v pořádku. Pokud bude vše ok, pustíme se do tvorby příspěvků a pošleme je do Facebooku na schválení. Celý proces trvá většinou kolem 6 hodin, maximálně jeden den. Hodně záleží právě na rychlosti a vytíženosti Facebook platformy a době, kdy nám registraci posíláte. Dobrá zpráva je ta, že fungujeme 7 dní v týdnu! Takže není vůbec problém něco registrovat například v sobotu večer a v neděli už vše běží.",
  },
  {
    question: "Jak se dozvím, že jste kampaň skutečně spustili?",
    answer:
      "Jednoduše - odkaz na reklamu vám pošleme okamžitě do emailu. Mimo to v průběhu kampaně vás budeme denně informovat o jejím dosahu a reakcích ostatních lidí.",
  },
  {
    question: "Vrátíte mi peníze pokud se náš mazlíček našel před spuštěním?",
    answer:
      "Ano, ale musíte být rychlejší než my. Jakmile se nám vaše nabídka objeví v administraci, okamžitě na ní začínáme pracovat a posíláme za ní peníze Facebooku. Pokud něco chcete zrušit - zavolejte nám urychleně na číslo 731 657 657.",
  },
  {
    question: "Proč jsou služby zpoplatněny?",
    answer:
      "Jak už jste se mohli dočíst, využíváme především placených reklam na platformách jako Facebook a Instagram. To je něco úplně jiného, než klasické příspěvky na stránkách se ztracenými zvířaty. Za dosah se platí a kampaň musí nastavit zkušený digitální marketér. Kromě toho váš inzerát musí projít také přes grafika, každý den s vámi komunikujeme, kampaň dále upravujeme, atd.",
  },
]
</script>

<script>
export default {
  name: 'FaqSection',
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MinusSmallIcon,
    PlusSmallIcon,
  },
  methods: {
    StructuredFaq(faqs) {
      let code = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": []
      };

      faqs.forEach((faq) => {
        code.mainEntity.push({
          "@type": "Question",
          "name": faq.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.answer
          }
        })
      });

      return JSON.stringify(code, null, 2);
    }
  }
}
</script>