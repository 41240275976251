
<template>
  <MainHeader></MainHeader>

  <div class="bg-white px-6 py-16 lg:px-8">
    <div class="mx-auto max-w-5xl text-base leading-7 text-stone-700">
      <p class="text-base font-semibold leading-7 text-orange-600">Právní dokument</p>
      <h1 class="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Obchodní podmínky služby Najdi Mazlíčka</h1>
      <div class="mt-10 max-w-5xl">
        <p class="mt-6"><span>Tyto obchodní podmínky (dále jen </span><strong>"obchodní podmínky"</strong><span>) Jiřího Štěpánka, se sídlem Na Kopci 164, 251 62 Svojetice, identifikační číslo 07539002, se sídlem Na Kopci 164, 251 62 Svojetice, zapsané v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 316877, upravují v souladu s ust. § 1751 odst. 1 Sb., občanský zákoník, ve znění pozdějších předpisů (dále jen </span><strong>"občanský zákoník"</strong><span>) podmínky a pravidla využití služby /pomoci najdimazlicka. Tato služba je poskytována Jiřím Štěpánkem (dále jen </span><strong>"společnost"</strong><span>) prostřednictvím najdimazlicka.cz. Obsahem pomoci najdimazlicka je poskytování pomoci v oblasti vyhledávání ztracených psů a jiných domácích zvířat. Rozsah pomoci a jejich cena je dána nabídkou společnosti (balíčkem jednotlivých forem pomoci) zveřejněnou na najdimazlicka.cz. Společnost je oprávněna ustavení těchto podmínek měnit či doplňovat. Nové podmínky jsou účinné dnem jejich zveřejnění na najdimazlicka.cz. Smluvní vztah mezi společností a klientem se řídí obchodními podmínkami účinnými k okamžiku doručení objednávky ze strany klienta společnosti.&nbsp;</span></p>
        <p class="mt-6"><strong>Poskytovatelem</strong><span> jednotlivých forem pomoci je </span><strong>Jiří Štěpánek</strong><span>, se sídlem Na Kopci 164, 251 62 Svojetice, identifikační číslo 07539002, se sídlem Na Kopci 164, 251 62 Svojetice, zapsané v obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 316877.</span></p>
        <p class="mt-6"><span>kontaktní údaje: jiri@palmmedia.cz, tel. 731657657.&nbsp;</span></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Realizace pomoci/služeb</h2>
        <p class="mt-6">S<span>lužba funguje on-line 24 hodin denně.&nbsp;</span></p>
        <p class="mt-6"><span>Předpokladem zahájení poskytování služby - realizace placených služeb/pomoci - je úhrada služby a vyplnění objednávky umístěné na najdimazlicka.cz.</span></p>
        
        <p class="mt-6"><span>Ve shora uvedené době společnost realizuje pomoc/službu </span><strong>“Hledání domácích zvířat pomocí sociálních sítí"</strong></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Klient</h2>
        <p class="mt-6"><span>Klientem může být právnická nebo fyzická osoba, která dosáhla 18 let a je plně způsobilá k právním jednáním, která souvisí s těmito obchodními podmínkami, dá souhlas se zpracováním osobních&nbsp; údajů, řádně vyplní objednávku na najdimazlicka.cz a uhradí společnosti odměnu ve výši, která je předpokladem pro zahájení poskytování jednotlivé formy pomoci.&nbsp;</span></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Uzavření smlouvy</h2>
        <p class="mt-6"><span>Smlouva, na základě které se společnost zavazuje poskytnout klientovi pomoc při hledání ztraceného psa nebo domácího zvířete, je uzavřena okamžikem, kdy společnost obdrží odměnu ve výši, která je předpokladem pro zahájení úkonů vedoucích k nalezení ztraceného psa nebo domácího zvířete. Smlouva je také uzavřena v okamžiku, kdy společnost obdrží objednávku od klienta přes webovou stránku najdimazlicka.cz.&nbsp; V objednávce je klient povinen uvést přesné a pravdivé údaje. Pokud dojde ke změně údajů u klienta, je klient takovou změnu povinen bezodkladně oznámit společnosti a to na e-mail jiri@palmmedia.cz. Společnost je oprávněna odmítnout objednávku a neposkytnout požadovanou pomoc/službu, pokud se může odůvodněně domnívat, že klient porušuje tyto obchodní podmínky či je v minulosti porušil.&nbsp;</span></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Záruky</h2>
        <p class="mt-6"><span>Společnost vzhledem na charakter nabízené pomoci/služby negarantuje dosažení výsledku, tj. neodpovídá za nalezení ztraceného zvířete. Uplatněné vady z poskytnutých služeb je třeba zasílat na jiri@palmmedia.cz.</span></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Spotřebitelská smlouva</h2>
        <p class="mt-6"><span>O spotřebitelskou smlouvu se jedná v případě, že klient je spotřebitelem, tj. pokud je fyzickou osobu a poskytnutí pomoci/služby je objednáno klientem mimo rámec jeho podnikatelské činnosti nebo mimo rámec samostatného výkonu jeho povolání. V případě spotřebitelské smlouvy se na klienta vztahuje ochrana spotřebitele dle právních předpisů.&nbsp;</span></p>
        <p class="mt-6"><span>Je-li klient spotřebitelem a vznikne-li mezi společností klientem spor ze smlouvy, který se společnosti a klientovi nepodaří vyřešit přímo, má klient právo obrátit se s tímto sporem na českou obchodní inspekci (www.coi.cz). Práva spotřebitelů hájí i jejich zájmová sdružení a jiné subjekty na jejich ochranu. Toto právo může klient uplatnit nejpozději do 1 roku ode dne, kdy u společnosti uplatnil poprvé právo, které je předmětem tohoto spotřebitelského sporu.&nbsp;</span></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Cena a platební podmínky</h2>
        <p class="mt-6"><span>Cena za pomoc/službu je cenou smluvní, je uvedena u každé nabízené pomoci. Klient odesláním objednávky potvrzuje souhlas s cenou. Cenu je možno hradit bezhotovostně platební kartou nebo jakýmkoliv jiným způsobem, který platební brána nabízí.</span></p>
        <p class="mt-6"><strong>Klient bere na vědomí, že uhrazení ceny za vybraný rozsah pomoci/služeb je předpokladem zahájení realizace pomoci.&nbsp;</strong></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Odstoupení od smlouvy</h2>
        <p class="mt-6"><span>Objednávku lze zrušit a od smlouvy odstoupit pouze ve lhůtách a za podmínek uvedených v těchto podmínkách.</span></p>
        <p class="mt-6"><span>Objednávku lze stornovat a odesláním stornovacího emailu na jiri@palmmedia.cz. Do emailu je klient povinen uvést číslo objednávky.&nbsp;</span></p>
        <p class="mt-6"><span>Od spotřebitelské smlouvy uzavřené mezi společností a klientem může klient odstoupit ve lhůtě 14 dnů ode dne uzavření smlouvy o poskytování pomoci/služeb. Oznámení o odstoupení od smlouvy je třeba zaslat na email jiri@palmmedia.cz. Přijetí oznámení od smlouvy společnost bez zbytečného odkladu emailem potvrdí.&nbsp; Odstoupením od smlouvy se smlouva od počátku ruší a hledí se na ni, jakoby nebyla uzavřena. V případě odstoupení od smlouvy společnost vrátí realizovanou platbu, kterou od klienta na základě smlouvy přijala, a to do 14 dnů od odstoupení od smlouvy, a to na účet, ze kterého byla platba odeslána společnosti nebo na účet uvedený v odstoupení od smlouvy.&nbsp; V souladu s ust. § 1837 občanského zákoníku nelze od smlouvy odstoupit v případě, že služby byla splněna s klientovým výslovným souhlasem před uplynutím lhůty pro odstoupení od smlouvy, tento důsledek klient berete na vědomí souhlasem s poskytnutí služby před uplynutím lhůty pro odstoupení od smlouvy, v takovém případě nelze přijatou platbu vrátit.&nbsp;</span></p>
        <p class="mt-6"><span>Společnost si vyhrazuje právo od smlouvy odstoupit v případě, že:</span></p>
        <p class="mt-6"><span>- pomoc/službu není z objektivních příčin možné za původních podmínek poskytnout,</span></p>
        <p class="mt-6"><span>- nebyly předem poskytnuty veškeré důležité informace (zejména fotky), tkající se ztraceného psa nebo domácích mazlíčka,</span></p>
        <p class="mt-6"><span>- plnění se stane objektivně nemožnými nebo protiprávním,</span></p>
        <p class="mt-6"><span>- v případě podstatného porušení těchto obchodních podmínek klientem.</span></p>
        <p class="mt-6"><span>V případě, že nastala některá z výše uvedených skutečností, společnost bude klienta o odstoupení společnosti od smlouvy neprodleně informovat a to na e-mail, ze kterého byla odeslána objednávka. Odstoupení je vůči klientovi účinné okamžikem, kdy je klientovi doručeno. Společnost vrátí přijatou platbu bezhotovostně na účet, ze kterého klient úhradu provedl, a to do 5 dnů od odstoupení od smlouvy.&nbsp;</span></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Informace o ochraně osobních údajů</h2>
        <p class="mt-6"><span>Společnost svou informační povinnost vůči klientovi ve smyslu čl. 13 Nařízení Evropského parlamentu a Rady 2016/679, o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) (dále jen "nařízení GDPR") související se zpracováním osobních údajů kupujícího pro účely plnění&nbsp; smlouvy o poskytnutí pomoci/služby, pro účely jednání o smlouvě o poskytnutí pomoci/služby a pro účely plnění veřejnoprávních povinností společnosti plní společnost prostřednictvím zvláštního dokumentu.</span></p>
        
        <h2 class="text-2xl font-bold tracking-tight text-gray-900 mb-6 mt-8">Ostatní informace</h2>
        <p class="mt-6"><span>Při uzavírání smlouvy jsou použity prostředky komunikace na dálku. Náklady vzniklé při použití prostředků komunikace na dálku hradí klient sám. Veškerá korespondence související se smlouvou mezi společností a klientem probíhá v písemné formě, a to emailem.&nbsp;</span></p>
        <p class="mt-6"><span>V případě, že některé ustanovení těchto obchodních podmínek je neplatné, neúčinné nebo nepoužitelné (nebo se takovým stane), použije se namísto něj ustanovení, které se svým smyslem nejvíce blíží neplatnému, neúčinnému či nepoužitelnému stanovení. Neplatností, neúčinností nebo nepoužitelností jednoho ustanovení není dotčena platnost ostatních ustanovení.&nbsp;</span></p>
        
        <p class="mt-6"><span>Tyto obchodní podmínky jsou platné a účinné od 17.9.2019.&nbsp;</span></p>
        
        <p class="mt-6"><span>Jiří Štěpánek</span></p>
      </div>
    </div>
  </div>

  <MainFooter></MainFooter>
</template>


<script setup>
import { useSeoMeta } from '@vueuse/head'


useSeoMeta ({
	title: 'Obchodní podmínky služby Najdi Mazlíčka | Najdi Mazlíčka',
	description: 'Předpokladem zahájení poskytování služby - realizace placených služeb/pomoci - je úhrada služby a vyplnění objednávky umístěné na najdimazlicka.cz.',
	ogDescription: 'Předpokladem zahájení poskytování služby - realizace placených služeb/pomoci - je úhrada služby a vyplnění objednávky umístěné na najdimazlicka.cz.',
	ogTitle: 'Obchodní podmínky služby Najdi Mazlíčka | Najdi Mazlíčka',
	ogImage: 'https://najdimazlicka.cz/og.jpg',
	twitterCard: 'summary_large_image',
  robots: "noindex"
});

</script>


<script>
import MainHeader from '../components/MainHeader.vue'
import MainFooter from '../components/MainFooter.vue'

export default {
  name: "VopView", 
  components: {
    MainHeader,
    MainFooter
  }
}
</script>